import React, { Component } from "react";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import TableHead from "@material-ui/core/TableHead";

import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import { Button } from "../../components";
import { tariffsForm } from "../../forms";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

class DistrictPrices extends Component {
  componentDidMount() {
    // this.props.getProviderDistricts({
    //   params: {path: `districts?provider_id=${this.props.match.params.providerId}`}
    // })
  }

  componentWillMount() {
    //this.props.fetchDistricts({query: {'without-pagination': 1}})
    this.props.fetchAllTariff({
      params: {
        path: `/providers/${this.props.match.params.providerId}/tariffs/all`,
      },
    });

    alert("test provider_id ");
    this.props.getProviderDistricts({
      params: { path: `districts` },
      query: {
        provider_id: this.props.match.params.providerId,
        "without-pagination": 1,
      },
    });
  }

  openFormModal = ({
    title = "",
    duck,
    submitText = "",
    form,
    Component,
    componentProps,
    noSubmit,
    submit,
    propName,
    additionalButtons = [],
    small = true,
  }) =>
    this.props.openModal({
      title,
      Component,
      componentProps,
      noSubmit,
      submitText,
      duck,
      submit,
      small,
      form,
      loading: false,
      modalLoading: false,
    });

  handleCreate = (values) =>
    this.props.storeTariffs(
      {
        params: {
          path: `providers/${this.props.match.params.providerId}/tariffs`,
        },
      },
      values
    );

  handleUpdate = (tariffsId) => (values) =>
    this.props.updateTariffs(
      {
        params: {
          path: `providers/${this.props.match.params.providerId}/tariffs/${tariffsId}`,
        },
      },
      values
    );

  componentWillReceiveProps(nextProps) {
    if (
      this.props.storeLoading &&
      !nextProps.storeLoading &&
      !nextProps.storeErrors
    ) {
      this.props.closeModal();
      this.props.fetchAllTariff({
        params: {
          path: `/providers/${this.props.match.params.providerId}/tariffs/all`,
        },
      });
    }
    if (
      this.props.updateLoading &&
      !nextProps.updateLoading &&
      !nextProps.updateErrors
    ) {
      this.props.closeModal();
      this.props.fetchAllTariff({
        params: {
          path: `/providers/${this.props.match.params.providerId}/tariffs/all`,
        },
      });
    }
  }

  render() {
    const {
      classes,
      translate,
      districts,
      allTariff,
      getProviderDistrictsData,
    } = this.props;

    return (
      <>
        <div
          onClick={() => window.history.back()}
          className="goBack_btn_link creat"
        >
          <KeyboardArrowLeftIcon className="goBack_btn" />
          <span>უკან დაბრუნება</span>
        </div>
        <br />
        <br />
        <br />

        <GridContainer>
          <GridItem xs={12}>
            <Card className={"distict_prices_card"}>
              <CardBody className={"distict_prices_cardBody"}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate("districts")}</TableCell>
                      {/* horizontal list */}
                      {(Array.isArray(getProviderDistrictsData)
                        ? getProviderDistrictsData
                        : []
                      ).map((district) => (
                        <TableCell
                          key={district.id}
                          style={{
                            textAlign: "center",
                            position: "sticky",
                            top: "-6px",
                            backgroundColor: "#fff",
                            zIndex: 11,
                          }}
                        >
                          {district.name}
                        </TableCell>
                      ))}
                      {/* </TableRow> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* vertical list */}
                    {(Array.isArray(getProviderDistrictsData)
                      ? getProviderDistrictsData
                      : []
                    ).map((district) => (
                      <TableRow key={district.id}>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: "-6px",
                            backgroundColor: "#fff",
                            zIndex: 11,
                          }}
                        >
                          {district.name}
                        </TableCell>
                        {(Array.isArray(getProviderDistrictsData)
                          ? getProviderDistrictsData
                          : []
                        ).map((d) => {
                          if (d.id < district.id) return null;

                          const filled = allTariff.filter(
                            ({ fromDistrictId, toDistrictId }) =>
                              (district.id === fromDistrictId &&
                                d.id === toDistrictId) ||
                              (district.id === toDistrictId &&
                                d.id === fromDistrictId)
                          )[0];

                          return (
                            <TableCell
                              key={d.id}
                              style={{ textAlign: "center", paddingRight: 48 }}
                            >
                              <Button
                                className="button-white"
                                color="primary"
                                onClick={() =>
                                  this.openFormModal({
                                    submitText: filled ? "edit" : "add",
                                    submit: filled
                                      ? this.handleUpdate(filled.id)
                                      : this.handleCreate,
                                    duck: "packages",
                                    form: tariffsForm,
                                    Component: "TariffsForm",
                                    componentProps: {
                                      districts: Array.isArray(
                                        getProviderDistrictsData
                                      )
                                        ? getProviderDistrictsData
                                        : [],
                                      formValues: filled
                                        ? filled
                                        : {
                                            deliveryFee: "",
                                            // courierFee: '',
                                            // approxTime: '',
                                            fromDistrictId: district.id,
                                            toDistrictId: d.id,
                                          },
                                    },
                                    small: true,
                                  })
                                }
                              >
                                {filled ? (
                                  /* ? `${filled.deliveryFee} / ${filled.courierFee} / ${filled.approxTime}` */
                                  `${filled.deliveryFee}`
                                ) : (
                                  <ErrorOutline />
                                )}
                              </Button>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withStyles(extendedFormsStyle)(DistrictPrices);
