import React, {Fragment} from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import {Manager, Target, Popper} from "react-popper"
import {voice} from '../../ducks'
import dashSorter, {sortDash} from '../../ducks/dashSorter'
import withStyles from "@material-ui/core/styles/withStyles"
import {MenuItem, MenuList, Paper, Grow, ClickAwayListener, Tooltip} from "@material-ui/core"

import Button from "../../components/CustomButtons/Button.jsx"
import headerLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle"
// import {DirectionsBike} from '@material-ui/icons'
import {connect} from 'react-redux'
import {setDefaultLocale} from 'redux-l10n/dist/actions'
import {Modal} from "../"
import {invalidateToken} from '../../ducks/authorization/actionCreators'
import {courierServices, dashboard, userInfo, userHubs} from '../../ducks'
// import loadingSvg from '../../assets/singleLoading.svg'
// import PreOrder from '../../views/Dashboard/PreOrders'
import {NavLink} from "react-router-dom"
import {courierPermissions} from "../../permissions";
import modal from '../../ducks/modal'
// import Settings from '@material-ui/icons/Settings'
// import CheckCircle from '@material-ui/icons/CheckCircle'
// import TurnedIn from '@material-ui/icons/TurnedIn'
// import PersonPinCircle from '@material-ui/icons/PersonPinCircle'
// import StoreMallDirectory from '@material-ui/icons/StoreMallDirectory'
// import Today from '@material-ui/icons/Today'
import VolumeOff from '@material-ui/icons/VolumeOff'
import VolumeUp from '@material-ui/icons/VolumeUp'
import DeviceHub from '@material-ui/icons/DeviceHub'
import AllOut from '@material-ui/icons/AllOut'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Motorcycle from '@material-ui/icons/Motorcycle'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'

const courierIcons = {
    PEDESTRIAN: <DirectionsRun className={'courierIcon'}/>,
    BIKE: <DirectionsBike className={'courierIcon'}/>,
    MOTORCYCLE: <Motorcycle className={'courierIcon'}/>,
    CAR: <DirectionsCar className={'courierIcon'}/>,
    OTHER: <AllOut className={'courierIcon'}/>
}

class HeaderLinks extends React.Component {
    state = {
        open: false,
        hubsOpen: false,
        alert: {
            open: false,
        },
        snackBar: {openPos: false, message: ''},
        pageIndex: 0,

        selectedHubs: [],
        
        // tabValue: parseInt(localStorage.getItem("tabValue") || 0)
    }

    showAlert = (query) => this.setState({
        alert: {
            open: true,
            ...query
        }
    })

    hideAlert = () => this.setState({
        alert: {open: false}
    })

    openModal = (modal) =>
        this.setState({
            modal: {
                isOpen: true,
                ...modal
            }
        })

    closeModal = (justClosed = true) => justClosed && this.setState({modal: {...this.state.modal, isOpen: false}})

    closeSnackBar = () =>
        this.setState({
            snackBar:
                {openPos: false, message: ''}
        })

    openFormModal = ({title = "", duck, submitText = "", form, Component, componentProps, noSubmit, submit, propName, additionalButtons = [], small = false}) => this.props.openModal && this.props.openModal({
        title,
        Component,
        componentProps,
        noSubmit: true,
        submitText,
        submit,
        small,
        form,
        duck,
        loading: false,
        modalLoading: false
    })

    handleClick = () => {
        this.setState({open: !this.state.open, hubsOpen: false})
    }

    handleClickHubs = () => {
        this.setState({hubsOpen: !this.state.hubsOpen, open: false})
    }

    handleClose = () => {
        this.setState({open: false})
    }

    handleCloseHubs = () => {
        this.setState({hubsOpen: false, open: false})
    }

    componentDidMount() {
        const {fetchCouriers, userInfo} = this.props

        userInfo.permissions[courierPermissions.read] && fetchCouriers({params: {path: `/couriers/available`}})
    }

    /* dashboard tab function */
    // TabHandleChange = (event, tabValue) => {
        
    //     localStorage.setItem('tabValue', tabValue); 
    //     this.setState({ tabValue });

    //     this.fetchData(tabValue === 0 ? 'collect' : 'deliver')
    
    // };
    
    refreshInfo = () => {
        // const { fetchProcessing, fetchCurrent, fetchPreOrder, fetchStats, fetchCouriers, userInfo, filterStatus } = this.props
        const { fetchProcessing, fetchCurrent, fetchPreOrder, fetchCouriers, userInfo, filterStatus } = this.props

        const query = filterStatus ? {
            'with-pagination': 1,
            status: filterStatus
        } : {
            'with-pagination': 1
        }


        // console.log("this.props.selectedTab ", (this.props && this.props.selectedTab));

        fetchPreOrder({params: {path: `/packages/pre-order`}, query: {'with-pagination': 1}})
        // fetchCurrent({params: {path: `/packages/current`}, query: { query, type: this.state.tabValue === 1 ? "deliver" : "collect" } })
        // fetchProcessing({params: {path: `/packages/processing`}, query: {'with-pagination': 1, type: this.state.tabValue === 1 ? "deliver" : "collect"}})

        fetchCurrent({params: {path: `/packages/current`}, query: { query, type: this.props.selectedTab } })
        fetchProcessing({params: {path: `/packages/processing`}, query: {'with-pagination': 1, type: this.props.selectedTab}})

        // fetchStats({params: {path: `/packages/stats`}})
        userInfo.permissions[courierPermissions.read] && fetchCouriers({params: {path: `/couriers/available`}})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userHubs.filter(h => h.selected).length !== this.props.userHubs.filter(h => h.selected).length) {
            this.refreshInfo()
        }

        // if (this.props.stats.microtime !== nextProps.stats.microtime) {
        //     nextProps.fetchStats({params: {path: `/packages/stats`}})
        // }
    }

    render() {
        const {classes, sortDash, userHubs, userHubsLoading, getUserHubs, translate, stats, couriers, couriersLoading, preOrderLoading, preOrder, userInfo, containerStyles = {}, voice, turnOn, turnOff} = this.props
        const {open, hubsOpen} = this.state
        // const searchButton =
        //   classes.top +
        //   " " +
        //   classes.searchButton
        const dropdownItem =
            classes.dropdownItem
        const managerClasses = classNames({
            [classes.managerClasses]: true
        })

        let selectedHubs = []
        let hubs = userHubs
            .map(h => {
                h.selected && selectedHubs.push(h.id)
                return ({
                    ...h,
                    name: h.selected
                        ? <span className={'flexSpan'}>
              <FiberManualRecord style={{color: 'green', width: 12, height: 12, marginRight: 5}}/> {h.name}
            </span>
                        : <span className={'flexSpan'}>
              <FiberManualRecord style={{opacity: 0, width: 12, height: 12, marginRight: 5}}/> {h.name}
            </span>
                })
            })

        return (
            <div style={containerStyles}>
                {/* <NavLink to={'/dashboard'}>
                    <Button
                        title={translate('processing')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => sortDash("PROCESSING")}
                    >
                        {
                            stats.PROCESSING !== undefined
                                ?
                                <Fragment>
                                    <Settings
                                        className={
                                            classes.headerLinksSvg +
                                            " " +
                                            classes.links
                                        }
                                    />
                                    <span className={classes.notifications}> {stats.PROCESSING}</span>
                                </Fragment>
                                : <img src={loadingSvg} width={20} alt={'loading'} />
                        }
                        
                    </Button>
                </NavLink>

                <NavLink to={'/dashboard'}>
                    <Button
                        title={translate('accepted')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => sortDash('ACCEPTED')}
                    >
                        {
                            stats.ACCEPTED !== undefined
                                ?
                                <Fragment>
                                    <TurnedIn
                                        className={
                                            classes.headerLinksSvg +
                                            " " +
                                            classes.links
                                        }
                                    />
                                    <span className={classes.notifications}>{stats.ACCEPTED}</span>
                                </Fragment>
                                : <img src={loadingSvg} width={20} alt={'loading'} />
                        }
                        
                    </Button>

                </NavLink>

                <NavLink to={'/dashboard'}>
                    <Button
                        title={translate('atProvider')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => sortDash('AT_PROVIDER')}
                    >
                        {
                            stats.AT_PROVIDER !== undefined
                                ?
                                <Fragment>
                                    <StoreMallDirectory
                                        className={
                                            classes.headerLinksSvg +
                                            " " +
                                            classes.links
                                        }
                                    />
                                    <span className={classes.notifications}>{stats.AT_PROVIDER}</span>
                                </Fragment>
                                : <img src={loadingSvg} width={20} alt={'loading'} />
                        }
                    </Button>
                </NavLink>

                <NavLink to={'/dashboard'}>
                    <Button
                        title={translate('pickedUp')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => sortDash('PICKED_UP')}
                    >
                        {
                            stats.PICKED_UP !== undefined
                                ?
                                <Fragment>
                                    <Motorcycle
                                        className={
                                            classes.headerLinksSvg +
                                            " " +
                                            classes.links
                                        }
                                    />
                                    <span className={classes.notifications}>{stats.PICKED_UP}</span>
                                </Fragment>
                                : <img src={loadingSvg} width={20} alt={'loading'} />
                        }
                    </Button>
                </NavLink>


                <NavLink to={'/dashboard'}>
                    <Button
                        title={translate('atRecipient')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => sortDash('AT_RECIPIENT')}
                    >
                        {
                            stats.AT_RECIPIENT !== undefined
                                ?
                                <Fragment>
                                    <PersonPinCircle
                                        className={
                                            classes.headerLinksSvg +
                                            " " +
                                            classes.links
                                        }
                                    />
                                    <span className={classes.notifications}>{stats.AT_RECIPIENT}</span>
                                </Fragment>
                                : <img src={loadingSvg} width={20} alt={'loading'}/>
                        }
                    </Button>
                </NavLink>

                <NavLink to={'/dashboard'}>
                    <Button
                        title={translate('all')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => sortDash(0)}
                    >
                        {
                            stats.ALL !== undefined
                                ?
                                <Fragment>
                                    <CheckCircle
                                        className={
                                            classes.headerLinksSvg +
                                            " " +
                                            classes.links
                                        }
                                    />
                                    <span className={classes.notifications}>{stats.ALL}</span>
                                </Fragment>
                                : <img src={loadingSvg} width={20} alt={'loading'} />
                        }
                    </Button>
                </NavLink>

                {
                    (userInfo.permissions.packages_preoder || userInfo.role !== 'provider') &&
                    <NavLink to={'/dashboard'}>
                        <Button
                            title={translate('preOrders')}
                            color="primary"
                            aria-label="Dashboard"
                            simple
                            justIcon
                            className={classes.buttonLink}
                            onClick={() =>
                                this.openFormModal({
                                    title: 'preOrders',
                                    Component: 'PreOrder',
                                    componentProps: {
                                        permissions: userInfo.permissions
                                    },
                                    small: false
                                })}
                        >
                            {
                                preOrderLoading ? <img src={loadingSvg} width={20} alt={'loading'}/> :
                                    <Fragment>
                                        <Today
                                            className={
                                                classes.headerLinksSvg +
                                                " " +
                                                classes.links
                                            }
                                        />
                                        <span className={classes.notifications}> {preOrder.length}  </span>
                                    </Fragment>
                            }
                        </Button>
                    </NavLink>
                } 
                {
                    userInfo.permissions[courierPermissions.read] &&
                    <Manager className={managerClasses} style={{display: 'inline-block'}}>
                        <Target>
                            <Button
                                title={translate('activeCouriers')}
                                color="primary"
                                simple
                                justIcon
                                aria-label="Couriers"
                                aria-owns={open ? "menu-list" : null}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                className={classes.buttonLink}
                                muiClasses={{
                                    label: ""
                                }}
                            >
                                {
                                    couriersLoading ?
                                        <img src={loadingSvg} width={20} alt={'loading'}/>
                                        :
                                        <Motorcycle
                                            className={
                                                classes.headerLinksSvg +
                                                " " +
                                                classes.links
                                            }
                                        />

                                }

                                <span className={classes.notifications}> {couriers.length} </span>
                            </Button>
                        </Target>
                        <Popper
                            placement="bottom-start"
                            eventsEnabled={open}
                            className={
                                classNames({[classes.popperClose]: !open}) +
                                " " +
                                classes.pooperResponsive
                            }
                        >
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <Grow
                                    in={open}
                                    id="menu-list"
                                    style={{transformOrigin: "0 0 0"}}
                                >
                                    <Paper className={classes.dropdown}>
                                        <MenuList role="menu">
                                            {
                                                couriers.map((courier, key) =>
                                                        <MenuItem
                                                            key={key}
                                                            onClick={this.handleClose}
                                                            className={dropdownItem}
                                                        >
                            <span className={'flexSpan'}>
                              {courierIcons[courier.transportationType]}
                                <span>{courier.name} - {courier.currentOrdersCount} - {courier.deliveredOrdersCount} </span>
                            </span>
                                                        </MenuItem>
                                                )
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            </ClickAwayListener>
                        </Popper>
                    </Manager>
                }
                {
                    userInfo.role !== 'provider' &&
                    <Manager className={managerClasses} style={{display: 'inline-block'}}>
                        <Target>
                            <Button
                                title={translate('hubs')}
                                color="primary"
                                simple
                                justIcon
                                aria-label="Hubs"
                                aria-owns={hubsOpen ? "menu-list" : null}
                                aria-haspopup="true"
                                onClick={this.handleClickHubs}
                                className={classes.buttonLink}
                                muiClasses={{
                                    label: ""
                                }}
                            >
                                {
                                    userHubsLoading ?
                                        <img src={loadingSvg} width={20} alt={'loading'}/>
                                        :
                                        <DeviceHub
                                            className={
                                                classes.headerLinksSvg +
                                                " " +
                                                classes.links
                                            }
                                        />

                                }

                                <span className={classes.notifications}> {selectedHubs.length} </span>
                            </Button>

                        </Target>
                        <Popper
                            placement="bottom-start"
                            eventsEnabled={hubsOpen}
                            className={
                                classNames({[classes.popperClose]: !hubsOpen}) +
                                " " +
                                classes.pooperResponsive
                            }
                        >
                            <ClickAwayListener onClickAway={this.handleCloseHubs}>
                                <Grow
                                    in={hubsOpen}
                                    id="menu-list"
                                    style={{transformOrigin: "0 0 0"}}
                                >
                                    <Paper className={classes.dropdown}>
                                        <MenuList role="menu">
                                            {
                                                hubs.map((hub, key) =>
                                                        <MenuItem
                                                            key={key}
                                                            onClick={() => {
                                                                const index = selectedHubs.indexOf(hub.id)

                                                                if (index > -1) {
                                                                    selectedHubs.splice(index, 1)
                                                                } else {
                                                                    selectedHubs.push(hub.id)
                                                                }

                                                                getUserHubs({params: {path: 'user/hubs'}}, {hubs: selectedHubs})
                                                            }}
                                                            className={dropdownItem}
                                                        >
                            <span className={'flexSpan'}>
                              <span>{hub.name} </span>
                            </span>
                                                        </MenuItem>
                                                )
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            </ClickAwayListener>
                        </Popper>
                    </Manager>

                }
*/}


                <NavLink to={'#'}>
                    <Button
                        title={translate('notificationVoice')}
                        color="primary"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        className={classes.buttonLink}
                        onClick={() => voice ? turnOff() : turnOn()}
                    >
                        {
                            voice ?
                                <VolumeUp
                                    className={classes.headerLinksSvg + " " + classes.links}
                                />
                                :
                                <VolumeOff
                                    className={classes.headerLinksSvg + " " + classes.links}
                                />
                        }
                    </Button>
                </NavLink>

            </div>
        )
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    stats: dashboard.stats.getData(state),
    // statsCount: state.dashboard.stats.count,
    // statsLoading: dashboard.stats.getRequestPending(state),
    userHubs: userHubs.getData(state),
    userHubsLoading: userHubs.getRequestPending(state),
    preOrder: dashboard.preOrder.getData(state).data,
    preOrderLoading: dashboard.preOrder.getRequestPending(state),
    couriersLoading: courierServices.available.getRequestPending(state),
    couriers: courierServices.available.getData(state),
    userInfo: userInfo.getData(state),
    voice: voice.getVoice(state),
    filterStatus: state.dashSorter.value,

  selectedTab: dashboard.selectedTab.getSelectedTab(state)

})

export default connect(
    mapStateToProps,
    {
        invalidateToken,
        setDefaultLocale,

        fetchProcessing: dashboard.processing.run,
        fetchCurrent: dashboard.current.run,
        // fetchStats: dashboard.stats.run,
        fetchPreOrder: dashboard.preOrder.run,
        fetchCouriers: courierServices.available.run,
        turnOn: voice.turnOn,
        turnOff: voice.turnOff,
        getUserHubs: userHubs.post,

        openModal: modal.openModal,
        closeModal: modal.closeModal,

        sortDash,
    }
)(withStyles(headerLinksStyle)(HeaderLinks))
