import React, { Component } from 'react'
import {Field, withForm} from 'ductus'
import {passwordChangeForm} from '../../forms'
import {GridItem, TextField, Button} from '../../components/index'
import { Lock, LockOpen } from '@material-ui/icons';

  class PasswordChange extends Component {
    state = {
      oldPassword: "",
      showOldPassword: true,

      password: "",
      showPassword: true,

      passwordConfirm: "",
      showPasswordConfirm: true,
    }

    oldPassword = (e) => this.setState({oldPassword: e.target.value})
    showOldPass = () => this.setState({showOldPassword: !this.state.showOldPassword})
    
    password = (e) => this.setState({password: e.target.value})
    showPassword = () => this.setState({showPassword: !this.state.showPassword})
    
    passwordConfirm = (e) => this.setState({passwordConfirm: e.target.value})
    showPasswordConfirm = () => this.setState({showPasswordConfirm: !this.state.showPasswordConfirm})
     
    onSubmit = (e) => {
      this.props.handleSubmit({
        oldPassword: this.state.oldPassword,
        password: this.state.password,
        passwordConfirm: this.state.passwordConfirm
      })
    }

    render() {  
      const {translate, disabled, isValid} = this.props;

      return (
        <form autoComplete="off">
          <input type={'text'} name={'email'}
                style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>
          <input type={'password'} name={'password'}
                style={{height: 0, overflow: 'hidden', padding: 0, margin: 0, border: 'none'}}/>

          <GridItem xs={12}>
            <div className='pass__line'>
              <Field
                name={'oldPassword'}
                label={translate('oldPassword')}
                component={TextField}
                type={this.state.showOldPassword == true ? 'password' : 'text'}
                showSuccess={false}
                value={this.state.oldPassword}
                inputProps={{
                  onChange: this.oldPassword,
                }}
              />
              <span className='icon_btn' onClick={this.showOldPass}>{this.state.showOldPassword == true ? <Lock/> : <LockOpen/>}</span>
            </div>
            <div className='pass__line'>
              <Field
                name={'password'}
                label={translate('password')}
                component={TextField}
                type={this.state.showPassword == true ? 'password' : 'text'}
                value={this.state.password}
                inputProps={{
                  onChange: this.password,
                }}
              />
              <span className='icon_btn' onClick={this.showPassword}>{this.state.showPassword == true ? <Lock/> : <LockOpen/>}</span>
            </div>
            <div className='pass__line'>
              <Field
                name={'passwordConfirm'}
                label={translate('passwordConfirm')}
                component={TextField}
                type={this.state.showPasswordConfirm == true ? 'password' : 'text'}
                value={this.state.passwordConfirm}
                inputProps={{
                  onChange: this.passwordConfirm,
                }}
              />
              <span className='icon_btn' onClick={this.showPasswordConfirm}>{this.state.showPasswordConfirm == true ? <Lock/> : <LockOpen/>}</span>
            </div>

            <div>
                <Button
                    color={'primary'}
                    disabled={disabled || !isValid}
                    onClick={this.onSubmit}
                >
                    {translate('update')}
                </Button>
            </div>
          </GridItem>
        </form>

      )
    }
  
  }

export default withForm(passwordChangeForm)(PasswordChange)
