import React, {Component} from 'react'
import MailOutline from '@material-ui/icons/MailOutline'
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline'
import Security from '@material-ui/icons/Security'
import InvertColors from '@material-ui/icons/InvertColors'

import ge from '../../assets/img/svgFlags/ge.svg'
import us from '../../assets/img/svgFlags/us.svg'
import ru from '../../assets/img/svgFlags/ru.svg'
import EmailChange from './EmailForm'
import PasswordChange from './PasswordForm'
import ComboBox from "../../components/ComboBox/ComboBox";
import Picker from './Picker'
import * as voices from '../../assets/voices'

import CardIcon from '../../components/Card/CardIcon'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import Card from '../../components/Card/Card'

const voiceKeys = Object.keys(voices)

class Settings extends Component {

  audio = {}

  componentDidMount(){
    const { changeEmailFormValue, userInfo: { email } } = this.props
    changeEmailFormValue('email', email )

  }

  handleSubmitEmail = () => this.props.updateEmail({ query: this.props.emailFormValues })
  handleSubmitPassword = (passwordProps) => this.props.updatePassword({ query: {...passwordProps, ...this.props.passwordFormValues} })
  handleChangeColor = (cName, color) => this.props.changeColor(cName, color.hex)

  render() {
    const {
      translate,
      setDefaultLocale,
      changeVoice,
      changeColor,
      Mui: { colors },
      sidebarConfigs: { sidebarBgColor, sidebarColor },
      changeSidebarConfig,
      setDefault
    } = this.props
    return (
      <GridContainer>

        <GridItem xs={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <MailOutline />
              </CardIcon>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.87)'}}> {translate('changeEmail')} </h4>
            </CardHeader>
            <CardBody>
              <EmailChange
                translate={translate}
                handleSubmit={this.handleSubmitEmail}
                disabled={false}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Security />
              </CardIcon>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.87)'}}> {translate('changePassword')} </h4>
            </CardHeader>
            <CardBody>
              <PasswordChange
                translate={translate}
                handleSubmit={this.handleSubmitPassword}
                disabled={false}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h4> {translate('languages')} </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} md={4} sm={6}>
                  <Button color={'primary'} onClick={() => setDefaultLocale('ka')}>
                    <img src={ge} width={27} height={20} alt="Georgian flag" />{" "}
                    <span className={'lang-label'}> {translate('georgian')} </span>
                  </Button>
                  {/*<div className={'lang-contain'}>*/}
                    {/*<img src={ge} width={27} height={20}/> <span className={'lang-label'}> {translate('georgian')} </span>*/}
                  {/*</div>*/}
                </GridItem>
                <GridItem xs={6} md={4} sm={6}>
                  <Button color={'primary'} onClick={() => setDefaultLocale('en')}>
                    <img src={us} width={27} height={20} alt="USA flag" />{" "}
                    <span className={'lang-label'}> {translate('english')} </span>
                  </Button>
                </GridItem>
                <GridItem xs={6} md={4} sm={6}>
                  <Button color={'primary'} onClick={() => setDefaultLocale('ru')}>
                    <img src={ru} width={27} height={20} alt="Russia flag" />{" "}
                    <span className={'lang-label'}> {translate('russian')} </span>
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h4> {translate('notificationVoices')} </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {
                  voiceKeys.map((voiceName, key) =>
                    <GridItem xs={6} sm={3} md={2} key={key} style={{ display: 'flex', alignItems: 'center'}}>
                      {/*<Button size={'sm'} round >  </Button>*/}
                      <PlayCircleOutline onClick={() => this.audio[voiceName].play() } style={{ color: 'black' }}/>
                      <Button color={'primary'} size={'sm'} onClick={() => changeVoice(voiceName)}> {voiceName} </Button>
                    </GridItem>
                  )
                }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <InvertColors />
              </CardIcon>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.87)'}}> {translate('changeColor')} </h4>
            </CardHeader>
            <CardBody>
              <div className={'wrapped-flex'}>
                {
                  Object.keys(colors).map( cName =>
                    <Picker color={colors[cName]} colorName={cName} changeColor={changeColor} />
                  )
                }
              </div>
            </CardBody>
            <CardBody>
              <h4> {translate('sidebarColors')} </h4>

              <GridContainer>
                <GridItem sm={12} md={6}>
                  <ComboBox
                    label={translate('bgColor')}
                    name={'sidebarBgColor'}
                    value={sidebarBgColor}
                    items={[
                      {id: 'white', name: 'white'},
                      {id: 'black', name: 'black'},
                      {id: 'blue', name: 'blue'},
                    ]}
                    onChange={(value) => changeSidebarConfig('sidebarBgColor', value)}
                  />
                </GridItem>

                <GridItem sm={12} md={6}>
                  <ComboBox
                    label={translate('sidebarColor')}
                    name={'sidebarColor'}
                    value={sidebarColor}
                    items={[
                      {id: 'white', name: 'white'},
                      {id: 'red', name: 'red'},
                      {id: 'orange', name: 'orange'},
                      {id: 'green', name: 'green'},
                      {id: 'blue', name: 'blue'},
                      {id: 'purple', name: 'purple'},
                      {id: 'rose', name: 'rose'}
                    ]}
                    onChange={(value) => changeSidebarConfig('sidebarColor', value)}
                  />

                </GridItem>
              </GridContainer>
              <Button color={'transparent'} onClick={setDefault}>
                {translate('setThemeToDefault')}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        {
          voiceKeys.map((voiceName, key) =>
            <audio ref={audio => this.audio[voiceName] = audio } src={voices[voiceName]} id={voiceName} key={`${voiceName}-${key}`}></audio>
          )
        }
      </GridContainer>
    )
  }
}

export default Settings