import React from "react"

import withStyles from '@material-ui/core/styles/withStyles'
import { Face, MailOutline, Home, PermIdentity, Phone, Lock, Message, AccountBalance, AccountBalanceWallet } from '@material-ui/icons';
import GridItem from '../../../../components/Grid/GridItem'
import GridContainer from '../../../../components/Grid/GridContainer'
//
import {Field} from 'ductus'

import TextField from "../../../../components/fields/TextField"

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
}

class SystemData extends React.Component {


  state = {
    password: '',
    passwordConfirm: '',
    bankAccountNumber: ''
  }

  onChangePassword = (e) => {
    this.setState({password: e.target.value})
    this.props.changeCreateFormValues({password: e.target.value})
  }
  onChangePasswordConfirm = (e) => {
    this.setState({passwordConfirm: e.target.value})
    this.props.changeCreateFormValues({passwordConfirm: e.target.value})
  }
  /*Bank Account Number*/
  bankAccountNumber = (e) => this.props.changeCreateFormValues({bankAccountNumber: e.target.value.slice(0, 22)})

  sendState() {
    return this.state
  }
  
  isValidated = () => true
  
  render() {
    const { getTranslator } = this.props
    return (
      <GridContainer justify="center">
        {/*<GridItem xs={12} sm={12}>*/}
        {/*/!*<h4 className={classes.infoText}>*!/*/}
        {/*/!*Let's start with the basic information (with validation)*!/*/}
        {/*/!*</h4>*!/*/}
        {/*</GridItem>*/}
        {/*<GridItem xs={12} sm={4}>*/}
        {/*<PictureUpload />*/}
        {/*</GridItem>*/}
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <legend> {getTranslator('systemData')} </legend>

          <Field
            name={'name'}
            label={getTranslator('name')}
            icon={Face}
            component={TextField}
            required={true}
          />

          <Field
            name={'email'}
            label={getTranslator('email')}
            icon={MailOutline}
            component={TextField}
            required={true}
          />

          <Field
            name={'phoneNumber'}
            label={getTranslator('phoneNumber', {})}
            icon={Phone}
            component={TextField}
            required={true}
          />

          <Field
            name={'address'}
            label={getTranslator('address')}
            icon={Home}
            component={TextField}
            required={true}
          />

          <Field
            name={'password'}
            type={'password'}
            label={getTranslator('password')}
            icon={Lock}
            component={TextField}
            required={true}
            value={this.state.password}
            inputProps={{
              onChange: this.onChangePassword,
            }}
            
          />

          <Field
            name={'passwordConfirm'}
            type={'password'}
            label={getTranslator('passwordConfirm')}
            icon={Lock}
            component={TextField}
            required={true}
            value={this.state.passwordConfirm}
            showSuccess= {
              this.state.password !== this.state.passwordConfirm ? false : true
            } 
            inputProps={{
              onChange: this.onChangePasswordConfirm,
            }}

          />
        
          { 
            this.state.password !== this.state.passwordConfirm &&
             <p className="error_note">* პაროლის ველების შევსება სავალდებულოა, პაროლის ველებში შეყვანილი ინფორმაცია არ ემთხვევა ერთმანეთს</p> 
          }
        
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <legend>{getTranslator('legalData')}</legend>

          <Field
            name={'legalName'}
            label={getTranslator('name')}
            icon={Face}
            component={TextField}
            required={true}
          />

          <Field
            name={'legalCode'}
            label={getTranslator('identityNumber')}
            icon={PermIdentity}
            component={TextField}
            required={true}
          />

          <Field
            name={'legalAddress'}
            label={getTranslator('address')}
            icon={Home}
            component={TextField}
            required={true}
          />

          <Field
            name={'bankName'}
            label={getTranslator('bankName')}
            icon={AccountBalance}
            component={TextField}
            required={true}
          />

          <Field
            name={'bankAccountNumber'}
            label={getTranslator('accountNumber')}
            icon={AccountBalanceWallet}
            component={TextField}
            required={true}
            // value={this.state.bankAccountNumber}
            inputProps={{
              onChange: this.bankAccountNumber,
            }}
          />

          <Field
            name={'bankCode'}
            label={getTranslator('bankCode')}
            icon={AccountBalance}
            component={TextField}
            required={true}
          />

        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <legend> {getTranslator('contactPerson')}</legend>

          <Field
            name={'contactName'}
            label={getTranslator('name')}
            icon={Face}
            component={TextField}
            required={true}
          />

          <Field
            name={'contactPhoneNumber'}
            label={getTranslator('phoneNumber', {})}
            icon={Phone}
            component={TextField}
            required={true}
          />

          <Field
            name={'contactEmail'}
            label={getTranslator('email')}
            icon={MailOutline}
            component={TextField}
            required={true}
          />

          <Field
            name={'comment'}
            label={getTranslator('comment')}
            icon={Message}
            component={TextField}
            inputProps={{
              multiline: true,
              rows: 3
            }}
          />

        </GridItem>


      </GridContainer>
    )
  }
}

export default withStyles(style)(SystemData)
