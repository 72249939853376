import React, {Component} from 'react'
import Pages from "../layouts/Pages.jsx"
import Dashboard from "../layouts/Dashboard.jsx"
import {Switch, Route, Redirect, Router} from 'react-router-dom'
import {
  verify,
  isAuthVerifyRequestPending,
  isAuthorizationRequestPending,
  getAuthorizationError,
  getAuthorizationToken
} from '../ducks/authorization'
import {dashboard, userInfo} from "../ducks"
import {connect} from "react-redux"
import loadingSvg from '../assets/loading.svg'
import {history} from '../store'
import Echo from "laravel-echo";
import { echoOptions } from "../defaultConfigs";

import {invalidateToken} from '../ducks/authorization'

const loadingStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

class AuthCheck extends Component {

  state = {
    loading: true,
    // tabValue: parseInt(localStorage.getItem("tabValue") || 0)
  }

  componentWillMount() {
    const { verify, authorizationToken } = this.props

    if (typeof authorizationToken !== 'string') {
      this.redirectToLogin()
    } else {
      verify()
    }
  }

  redirectToLogin = () => {

    this.setState({loading: false })

    this.props.history.push({
      pathname: '/login',
      state: {from: this.props.location}
    })

  }
  /* dashboard tab function */
  // TabHandleChange = (event, tabValue) => {
        
  //   localStorage.setItem('tabValue', tabValue); 
  //   this.setState({ tabValue });

  //   this.fetchData(tabValue === 0 ? 'collect' : 'deliver')

  // };

  handleEvent = e => {

console.log("soket handleEvent,  soket ", e);
    
    // const { sendEvent, eventOccured, fetchCurrent, fetchProcessing, fetchPreOrder, fetchStats, fetchPost } = this.props
    const { sendEvent, eventOccured, fetchCurrent, fetchProcessing, fetchPreOrder, fetchStats, fetchPost } = this.props

    // if(e.type === 'PACKAGES_CREATED' || e.type === 'PACKAGE_CREATED' || e.type === 'PACKAGE_UPDATED' ){
    if(e.type === 'PACKAGES_CREATED' || e.type === 'PACKAGE_CREATED'){

      fetchPreOrder({params: {path: `/packages/pre-order`}, query: { 'with-pagination': 1}})
      fetchCurrent({params: {path: `/packages/current`}, query: { 'with-pagination': 1, type: this.props.selectedTab }})
      fetchProcessing({params: {path: `/packages/processing`}, query: { 'with-pagination': 1, type: this.props.selectedTab}})
      // fetchCurrent({params: {path: `/packages/current`}, query: { 'with-pagination': 1, type: this.state.tabValue === 1 ? "deliver" : "collect" }})
      // fetchProcessing({params: {path: `/packages/processing`}, query: { 'with-pagination': 1, type: this.state.tabValue === 1 ? "deliver" : "collect" }})
      // fetchStats({params: {path: `/packages/stats`}});
      fetchPost({params: {path: `/packages/delivered-to-post`}, query: { 'with-pagination': 1}})
    }
    
    if(e.type !== 'PACKAGE_CREATED' || e.type !== 'PACKAGE_UPDATED') {
      sendEvent(e);
    }
    e.notify && eventOccured(e)
  }

  componentWillReceiveProps(nextProps) {

// console.log("componentWillReceiveProps(nextProps) ");


    const { authPending, verifyPending, getUserInfo, userInfoLoading, userInfo, invalidateToken } = this.props
    if(!userInfo.role){
      if (nextProps.authPending || nextProps.verifyPending) {
        this.setState({ loading: true })
      }

      if ((verifyPending && !nextProps.verifyPending)
        && ((typeof nextProps.authorizationToken !== 'string')  || nextProps.verifyError)
      ) {
        invalidateToken()
        return this.redirectToLogin()
      }
      else if ((verifyPending && !nextProps.verifyPending && !nextProps.verifyError) || (authPending && !nextProps.authPending)) {
        getUserInfo()
      }
      else if (!authPending && nextProps.authPending) {
        this.setState({loading: true})
      }
      else if (userInfoLoading && !nextProps.userInfoLoading && !nextProps.userInfoError) {

        const echo = new Echo(echoOptions)

        echo.channel(`user.${nextProps.userInfo.id}`).listen('.action', this.handleEvent)

        this.setState({loading: false})

      } else if (nextProps.userInfoError) {
        this.setState({loading: false})
      }
    }

  }

  // shouldComponentUpdate(nextProps, nextState){
  //   return JSON.stringify(this.props) !== JSON.stringify(nextProps)
  //   // return !equal(this.props, nextProps)
  // }

  render() {
    const {loading} = this.state
    const {authorizationToken, location, userInfo} = this.props

    return (
      <Router history={history}>
        {
          // todo: პერმიშენების არსებობით ირკვევა userInfo თუ არსებობს. თუ არ არსებობს აპლიკაციაც ვერ იმუშავებს და ვერ განახორციელებს მომდევნო მოთხოვნებს
          loading
            ?
            <img src={loadingSvg} style={loadingStyles} alt="loading"
            />
            :
            typeof authorizationToken !== 'string'
              ?
              <Switch>
                <Route
                  path={'/'}
                  render={this.renderPage}
                />
                <Redirect to={{
                  pathname: "/login",
                  state: {from: location}
                }}
                />
              </Switch>
              :
              !userInfo.permissions ? <img src={loadingSvg} style={loadingStyles} alt="loading spiner" /> :
                <Switch>
                  <Route
                    path={'/'}
                    render={(this.renderDashboard)}
                  />
                  <Redirect to={'/'}/>
                </Switch>
        }
      </Router>
    )
  }

  renderDashboard = (props) =>
    <Dashboard
      {...props}
      translate={this.props.translate}
      permissions={this.props.userInfo.permissions}
      userInfo={this.props.userInfo}
    />

  renderPage = (props) =>
    <Pages
      {...props}
      translate={this.props.translate}
    />
}


const mapStateToProps = (state) => ({
  authorizationToken: getAuthorizationToken(state),
  authorizationError: getAuthorizationError(state),

  authPending: isAuthorizationRequestPending(state),
  verifyPending: isAuthVerifyRequestPending(state),
  verifyError: state.authorization.verify.error,
  userInfo: userInfo.getData(state),
  userInfoLoading: userInfo.getRequestPending(state),
  userInfoError: userInfo.getRequestError(state),

  selectedTab: dashboard.selectedTab.getSelectedTab(state),


  devTest: sendEvent.e
})

const getUserInfo= userInfo.run
const sendEvent = e => e
const eventOccured = e => ({ type: 'EVENT/OCCURRED', payload: e})

export default connect(
  mapStateToProps,
  {
    verify,
    getUserInfo,
    sendEvent,
    eventOccured,
    invalidateToken,
    fetchProcessing: dashboard.processing.run,
    fetchCurrent: dashboard.current.run,
    // fetchStats: dashboard.stats.run,
    fetchPreOrder: dashboard.preOrder.run,
    fetchPost: dashboard.post.run,

  }
)(AuthCheck)
